var payments;
var card;
var paymentResults
var amount;

function getPayments(){
  return payments;
}

function getCard(){
  return card;
}

async function initPayments(applicationId,locationId) {
  if (!window.Square) {
    console.log('Square.js failed to load properly');
  }
  this.locationId = locationId;

  try {
    payments = window.Square.payments(applicationId, locationId);
  } catch {
    const statusContainer = document.getElementById(
      'payment-status-container'
    );
    statusContainer.className = 'missing-credentials';
    statusContainer.style.visibility = 'visible';
    return;
  }

  try {
    card = await initializeCard(payments);
  } catch (e) {
    console.error('Initializing Card failed', e);
    return;
  }
}


async function initializeCard(payments) {
  const card = await payments.card();
  await card.attach('#card-container');
  return card;
}

async function createPayment(apiPayment,body) {
  const paymentResponse = await fetch(apiPayment, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body
  });

  if (paymentResponse.ok) {
    return paymentResponse.json();
  }
  const errorBody = await paymentResponse.text();
  throw new Error(errorBody);
}

async function tokenize(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === 'OK') {
    return tokenResult.token;
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(
        tokenResult.errors
      )}`;
    }
    throw new Error(errorMessage);
  }
}


async function verifyBuyer(payments, token, verificationDetailsStr) {
  const jsonVerificationDetails = JSON.parse(verificationDetailsStr);
  const verificationDetails = {
    amount: jsonVerificationDetails.amount,
    billingContact: {
      addressLines: [jsonVerificationDetails.billingContact.addressLines, 'Apartment 1'],
      familyName: jsonVerificationDetails.billingContact.familyName,
      givenName: jsonVerificationDetails.billingContact.givenName,
      email: jsonVerificationDetails.billingContact.email,
      country: jsonVerificationDetails.billingContact.country,
      phone: jsonVerificationDetails.billingContact.phone,
      region: jsonVerificationDetails.billingContact.region,
      city: jsonVerificationDetails.billingContact.city,
    },
    currencyCode: jsonVerificationDetails.currencyCode,
    intent: jsonVerificationDetails.intent,
  };



  const verificationResults = await payments.verifyBuyer(
    token,
    verificationDetails
  );
  return verificationResults.token;
}

      // status is either SUCCESS or FAILURE;
function displayPaymentResults(status) {
        const statusContainer = document.getElementById(
          'payment-status-container'
        );
        if (status === 'SUCCESS') {
          statusContainer.classList.remove('is-failure');
          statusContainer.classList.add('is-success');
        } else {
          statusContainer.classList.remove('is-success');
          statusContainer.classList.add('is-failure');
        }
        statusContainer.style.visibility = 'visible';
}

async function handlePaymentMethodSubmission(apiPayment,card, payments, body, verificationDetails) {
  let cardButton = document.getElementById('card-button');
  const jsonBody = JSON.parse(body);
  try {
    cardButton.disabled = true;
    const token = await tokenize(card);
    const verificationToken = await verifyBuyer(payments, token, verificationDetails);
    jsonBody.sourceId = token
    jsonBody.verificationToken = verificationToken
    paymentResults = await createPayment(
      apiPayment,
      JSON.stringify(jsonBody)
    );
    displayPaymentResults('SUCCESS');
    console.debug('Payment Success', paymentResults);
    return paymentResults;
  } catch (e) {
    cardButton.disabled = false;
    displayPaymentResults('FAILURE');
    console.error(e.message);
  }
}
